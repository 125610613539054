import React from "react";
import { useState, createContext, useContext } from "react";
import "./App.scss";
import appContext from "./context/app.context";
import {
  EToasterVariants,
  ToasterProperties,
} from "services/types/common.type";
import { toasterDuration } from "./common/constant";
import Spinner from "./components/Spinner";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LoginPage from "./pages/DashboardPage";
import LandingPage from "pages/LandingPage";


var timeout: any;

const App: React.FC = () => {
  
  const [spinnerVisibility, setSpinnerVisibility] = useState<boolean>(false);
  const [sideMenuVisibility, setSideMenuVisibility] = useState<boolean>(true);

  const UserContext = React.createContext(null);
  const [user, setUser] = useState<any>(null);

  const contextItems = {
    spinnerVisibility,
    setSpinnerVisibility,
    sideMenuVisibility,
    setSideMenuVisibility,
  };

  console.log(user,'app')

  return (
    <div className="App">
      <UserContext.Provider value={user}>
      <appContext.Provider value={contextItems}>
        <Router>
          <Routes>
            
            <Route path="/offers/*" element={ <LandingPage />} />
          </Routes>
        </Router>
        <Spinner />
      </appContext.Provider>
      </UserContext.Provider>
    </div>
  );
};

export default App;
