import React from 'react'
import { EToasterVariants } from "../services/types/common.type";

const appState = {
    sideMenuVisibility: false,
    setSideMenuVisibility: (visibility: boolean) => { },
    spinnerVisibility: false,
    setSpinnerVisibility: (visibility: boolean) => { },
    
}
export const appContext = React.createContext(appState);


export default appContext;
