import React, { useContext, useState, useEffect } from "react";
import { EToasterVariants } from "../../services/types/common.type";
import "./style.scss";
import { TextField, TextFieldProps } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import DraftsIcon from "@mui/icons-material/Drafts";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import { DateTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import RemoveIcon from "@mui/icons-material/Remove";
import { makeStyles } from "@mui/styles";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";

import FormControl from "@mui/material/FormControl";

import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";

interface FormData {
  startDate: any;
  EndDate: any;
  offerTypes: any;
  leavingFrom: any;
  ticketType: any;
  childrenType: any;
  adultType: any;
}

const CountryTypes = [
  { label: "Thailand" },
  { label: "Taiwan" },
  { label: "United Kingdom" },
  { label: "United States" },
  { label: "Sri Lanka" },
  { label: "Sudan" },
  { label: "Spain" },
];

const useStyles = makeStyles((theme) => ({
  select: {
    width: "200px", // Set the width here
  },
  childinnerwrp: {
    display: "flex",

    gap: "10px",
    flexWrap: "wrap",
    marginTop: "10px",
    width: "310px",
  },
  childselect: {
    minWidth: "150px",
  },
}));

const DashboardPage = () => {
  const classes = useStyles();
  const [openPop, setOpenPop] = useState(false);
  const [count, setCount] = useState(0);
  const [countchild, setCountchild] = useState(0);
  const [isShown, setIsShown] = useState<any>(false);

  const [inputs, setInputs] = useState([{ value: "" }]);

  const [formData, setFormData] = useState<FormData>({
    startDate: "",
    EndDate: "",
    offerTypes: "",
    leavingFrom: "",
    ticketType: "",
    childrenType: "",
    adultType: "",
  });

  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    setCount(count - 1);
  };

  const incrementchild = () => {
    setCountchild(countchild + 1);
    setIsShown(true);
    const values = [...inputs];
    values.push({ value: "" });
    setInputs(values);
  };

  const decrementchild = () => {
    setCountchild(countchild > 1 ? countchild - 1 : 1);
    const values = [...inputs];
    values.pop();
    setInputs(values);
  };

  const handleInputChange = (index: any, event: any) => {
    const values = [...inputs];
    values[index].value = event.target.value;
    setInputs(values);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  

  const handleSubmit = (event: any) => {
    event.preventDefault();
    //setOpen(true);
    const values = [...inputs];
    setFormData({ ...formData, childrenType: values });

    const now = moment();
    const startDate = moment(formData.startDate).toDate();
  };

  const updatePublished = () => {
    setOpenPop(true);
  };

  const handleClosePop = () => {
    setOpenPop(false);
  };

  return (
    <div className="ticket-card">
      <div>Booking Your Tickets</div>

     

      

      <form onSubmit={handleSubmit}>
        <div className="booking-order">
          <div>
            <Button onClick={handleClick} endIcon={<KeyboardArrowDownIcon />}>
              1 traveller
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <div className="add-main-wrp">
                <div className="add-memb-wrp">
                  <div>Adults</div>

                  <div className="add-icon-wrp">
                    <div>
                      <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        onClick={decrement}
                      >
                        <RemoveIcon />
                      </Fab>
                    </div>

                    <div className="form-count">
                      <input value={count} />
                    </div>

                    <div>
                      <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        onClick={increment}
                      >
                        <AddIcon />
                      </Fab>
                    </div>
                  </div>
                </div>

                <div className="add-childer">
                  <div className="add-memb-wrp">
                    <div>Children</div>

                    <div className="add-icon-wrp">
                      <div>
                        <Fab
                          size="small"
                          color="primary"
                          aria-label="add"
                          onClick={decrementchild}
                        >
                          <RemoveIcon />
                        </Fab>
                      </div>

                      <div className="form-count">
                        <input value={countchild} />
                      </div>

                      <div>
                        <Fab
                          size="small"
                          color="primary"
                          aria-label="add"
                          onClick={incrementchild}
                        >
                          <AddIcon />
                        </Fab>
                      </div>
                    </div>
                  </div>

                  <div>
                    {isShown ? (
                      <div className={classes.childinnerwrp}>
                        {inputs.map((input, i) => (
                          <FormControl>
                            <div>
                              <InputLabel id="demo-simple-select-label">
                                child {i} age
                              </InputLabel>
                              <Select
                                className={classes.childselect}
                                value={input.value}
                                onChange={(event) =>
                                  handleInputChange(i, event)
                                }
                                labelId="demo-simple-select-label"
                                id={`input-${i}`}
                                name={`input-${i}`}
                                label={`Input ${i}`}
                              >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                              </Select>
                            </div>
                          </FormControl>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="done-btn">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                  >
                    Done
                  </Button>
                </div>
              </div>
            </Menu>
          </div>
          <div className="simbu">
            <FormControl>
              <InputLabel id="demo-simple-select-label">Class</InputLabel>
              <Select
                className={classes.select}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.ticketType}
                label="Class"
                onChange={(event) => {
                  setFormData({ ...formData, ticketType: event.target.value });
                }}
              >
                <MenuItem value={"Economy"}>Economy</MenuItem>
                <MenuItem value={"Premium economy"}>Premium economy</MenuItem>
                <MenuItem value={"Business class"}>Business class</MenuItem>
                <MenuItem value={"First class"}>First class</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="ticket-form">
          <div className="search-left">
            <div className="form-control">
              <Autocomplete
                options={CountryTypes}
                value={formData.offerTypes}
                onChange={(_event, newValue) => {
                  setFormData({ ...formData, offerTypes: newValue });
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: <AddLocationAltOutlinedIcon />,
                        endAdornment: null,
                      }}
                      label="leaving from"
                      fullWidth
                    />
                  );
                }}
              />
            </div>

            <FlipCameraAndroidIcon />
            <div className="form-control">
              <Autocomplete
                options={CountryTypes}
                value={formData.leavingFrom}
                onChange={(_event, newValue) => {
                  setFormData({ ...formData, leavingFrom: newValue });
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: <AddLocationAltOutlinedIcon />,
                        endAdornment: null,
                      }}
                      label="Going To"
                      fullWidth
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="input-right">
            <div className="form-control">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Departing"
                  value={formData.startDate}
                  onChange={(newValue) => {
                    setFormData({ ...formData, startDate: newValue });
                  }}
                />
              </LocalizationProvider>
            </div>

            <div className="form-control">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Returning"
                  value={formData.EndDate}
                  onChange={(newValue) => {
                    setFormData({ ...formData, EndDate: newValue });
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>

        <div className="btn-wrp">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={updatePublished}
          >
            Search
          </Button>

          
        </div>
      </form>

      <Dialog onClose={handleClosePop} open={openPop}>
        <div className="inner-pop">
          <div className="close-btn" onClick={handleClosePop}>
            <CloseIcon />
          </div>

          <div className="form-data">
            <div>
              <h4>Class : {formData.ticketType}</h4>
              <h4>From : {formData.offerTypes.label}</h4>
              <h4>To : {formData.leavingFrom.label}</h4>
              <h4>
                Start Date : {formData.startDate.$D}-{formData.startDate.$M + 1}
                -{formData.startDate.$y}
              </h4>
              <h4>
                End Date : {formData.EndDate.$D}-{formData.EndDate.$M + 1}-
                {formData.EndDate.$y}
              </h4>
              {formData.childrenType.length >= 1 && (
                <>
                  <h4>Children 1 : {formData.childrenType[0].value} Age</h4>
                  <h4>Children 2 : {formData.childrenType[1].value} Age</h4>
                </>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DashboardPage;
