import React, { useContext } from 'react';

import './styles.scss'
import buffer from 'assets/buffer.gif';
import poorvikaLogo from 'assets/login.jpg'
import appContext from 'context/app.context';

/* Created to implement the loading effect on the API calls */
const Spinner: React.FC = () => {

    const { spinnerVisibility } = useContext(appContext);

    return (
        <div className={spinnerVisibility ? 'spinner-dialog' : 'hide-element'}>
            <div className="spinner-card">
            <img src="#" alt="loader" className="application-brand"/>
            <p className="loading-text">Loading ...</p>
            <img src={buffer} alt="loader" className="spinner-animation"/>
            </div>
        </div>
    )
}

export default Spinner
