import React, { useContext } from "react";
import appContext from "context/app.context";
import "./styles.scss";
import AppHeader from "components/AppHeader";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import DashboardPage from "pages/DashboardPage";

const LandingPage = () => {
  const { sideMenuVisibility } = useContext(appContext);

  return (
    <div className="landing-page">
      <div className={sideMenuVisibility ? "page-layout" : "full-page-layout"}>
        <AppHeader />

        <div className="page-content">
          <Routes>
            <Route path="/dashboard/" element={<DashboardPage />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
