import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faBars } from '@fortawesome/free-solid-svg-icons'
import logo from "assets/images/logo.svg"
import "./styles.scss"
import { useNavigate } from 'react-router-dom'
import appContext from 'context/app.context';

const AppHeader: React.FC = () => {

    const navigatorTo = useNavigate();
    const { sideMenuVisibility , setSideMenuVisibility } = useContext(appContext);


    const logoutHandler = async (): Promise<void> => {
        localStorage.clear();
        navigatorTo("/");
    }
    
  return (
    <div className="app-header" id="top-header">
        <div className='brand-wrapper'>
             <img src={logo}alt="logoimg"/>
        </div>
        <div className="icons-wrapper">
        <div className="logout-button">
        <FontAwesomeIcon className='action-icon cursor-pointer ' icon={faArrowRightFromBracket} onClick={logoutHandler} />
        <p>Logout</p>
        </div>
        

        </div>
        
    </div>
  )
}

export default AppHeader